<template>
  <div>

    <CSTabBar :tabbar="tabBar" :checked-tab="checkedTabBar" @changeTabBar="changeTabBar"/>
    <div class="filter-panel" v-show="checkedTabBar !== TAB_BAR.SYSTEM">
      <CSSelect>
        <select v-model="queryParams.dutyType">
          <option value="">全部考核部门</option>
          <option v-for="(name, type) in departmentTypes" :key="type"
            :value="type">
            {{name}}
          </option>
        </select>
      </CSSelect>
      <CSSelect style="margin-right: 30px;">
        <select v-model="queryParams.evaluatorId">
          <option value="">全部检查人</option>
          <option v-for="staff in staffList" :key="staff.id"
            :value="staff.id">
            {{staff.name}}
          </option>
        </select>
      </CSSelect>
      <button class="btn btn-primary" @click="getAssessmentList()">查询</button>
    </div>


    <div class="result-panel">
      <CSTable v-if="TAB_BAR.BUSINESS === checkedTabBar" :thead-top="filterHeight">
          <template v-slot:header>
              <div class="table-header-panel text-right">
                  <button class="btn btn-primary sticky-right" @click="openAddAssessmentDialog">
                      <svg class="icon" aria-hidden="true">
                          <use xlink:href="#icon-menujiahao"></use>
                      </svg>
                      业务考核
                  </button>
              </div>
          </template>
          <template v-slot:thead>
              <tr>
                  <th>创建时间</th>
                  <th>考核名称</th>
                  <th>考核项</th>
                  <th>考核部门</th>
                  <th>检查人</th>
                  <th>操作</th>
              </tr>
          </template>
          <template v-slot:tbody>
              <template v-if="assessmentList && assessmentList.length > 0">
                  <tr v-for="item in assessmentList"
                      :key="item.id">
                      <td>{{item.createTime || '-'}}</td>
                      <td>{{item.name || '-'}}</td>
                      <td>
                          <template v-if="item.itemCount !== undefined">
                              <span class="allow-click" @click="openAssessmentItemsDialog(item.id, item.itemCount, 1)">{{item.itemCount}}</span>
                          </template>
                          <template v-else>-</template>
                      </td>
                      <td>{{departmentTypes[item.dutyType] || '-'}}</td>
                      <td>
                          <template v-if="item.evaluatorUsers && item.evaluatorUsers.length > 0">
                              <div class="allow-click" @click="lookInspector(item.evaluatorUsers)">
                                  {{item.evaluatorUsers.map(staff => {
                                  return staff.name;
                              }).join('、')}}
                              </div>
                          </template>
                          <template v-else>-</template>
                      </td>
                      <td>
                          <div class="btn-group">
                              <button
                                  type="button"
                                  class="btn btn-primary dropdown-toggle"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                              >
                                  操作
                              </button>
                              <ul class="dropdown-menu">
                                  <li @click="openAddInspector(item)"><a style="width: 100%;">设置检查人</a></li>
                                  <li @click="openUploadExcel(item.id)"><a style="width: 100%;">添加考核项</a></li>
                                  <li @click="changeAssessmentEnableStatus(item)"><a style="width: 100%;">{{item.isEnable ? '禁用' : '启用'}}</a></li>
                                  <li @click="openEditAssessmentDialog(item)"><a style="width: 100%;">修改</a></li>
                                  <li @click="deleteAssessment(item.id)"><a style="width: 100%;">删除</a></li>
                              </ul>
                          </div>
                      </td>
                  </tr>
              </template>
          </template>
      </CSTable>
      <CSTable :thead-top="filterHeight" v-if="TAB_BAR.COMMON === checkedTabBar">
          <template v-slot:header>
              <div class="table-header-panel text-right">
                  <button class="btn btn-primary sticky-right" @click="openAddAssessmentDialog">
                      <svg class="icon" aria-hidden="true">
                          <use xlink:href="#icon-menujiahao"></use>
                      </svg>
                      通用考核
                  </button>
              </div>
          </template>
          <template v-slot:thead>
              <tr>
                  <th>创建时间</th>
                  <th>考核名称</th>
                  <th>考核项</th>
                  <th>考核部门</th>
                  <th>检查人</th>
                  <th>操作</th>
              </tr>
          </template>
          <template v-slot:tbody>
              <template v-if="assessmentList && assessmentList.length > 0">
                  <tr v-for="item in assessmentList"
                      :key="item.id">
                      <td>{{item.createTime || '-'}}</td>
                      <td>{{item.name || '-'}}</td>
                      <td>
                          <template v-if="item.itemCount !== undefined">
                              <span class="allow-click" @click="openAssessmentItemsDialog(item.id, item.itemCount, 2)">{{item.itemCount}}</span>
                          </template>
                          <template v-else>-</template>
                      </td>
                      <td>{{departmentTypes[item.dutyType] || '-'}}</td>
                      <td>
                          <template v-if="item.evaluatorUsers && item.evaluatorUsers.length > 0">
                              <div class="allow-click" @click="lookInspector(item.evaluatorUsers)">
                                  {{item.evaluatorUsers.map(staff => {
                                  return staff.name;
                              }).join('、')}}
                              </div>
                          </template>
                          <template v-else>-</template>
                      </td>
                      <td>
                          <div class="btn-group">
                              <button
                                  type="button"
                                  class="btn btn-primary dropdown-toggle"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                              >
                                  操作
                              </button>
                              <ul class="dropdown-menu">
                                  <li @click="openAddInspector(item)"><a style="width: 100%;">设置检查人</a></li>
                                  <li @click="openUploadExcel(item.id)"><a style="width: 100%;">添加考核项</a></li>
                                  <li @click="changeAssessmentEnableStatus(item)"><a style="width: 100%;">{{item.isEnable ? '禁用' : '启用'}}</a></li>
                                  <li @click="openEditAssessmentDialog(item)"><a style="width: 100%;">修改</a></li>
                                  <li @click="deleteAssessment(item.id)"><a style="width: 100%;">删除</a></li>
                              </ul>
                          </div>
                      </td>
                  </tr>
              </template>
          </template>
      </CSTable>
      <CSTable :thead-top="filterHeight" v-if="TAB_BAR.SYSTEM === checkedTabBar">
          <template v-slot:header>
              <div class="table-header-panel text-right">
                  <button class="btn btn-primary sticky-right" @click="openAddAssessmentDialog">
                      <svg class="icon" aria-hidden="true">
                          <use xlink:href="#icon-menujiahao"></use>
                      </svg>
                      系统考核
                  </button>
              </div>
          </template>
          <template v-slot:thead>
              <tr>
                  <th>考核项</th>
                  <th>考核部门</th>
                  <th>检查人</th>
                  <th>操作</th>
              </tr>
          </template>
          <template v-slot:tbody>
              <template v-if="assessmentList && assessmentList.length > 0">
                  <tr v-for="item in assessmentList"
                      :key="item.id">
                      <td>
                          <template v-if="item.itemCount !== undefined">
                              <span class="allow-click" @click="openAssessmentItemsDialog(item.id, item.itemCount)">{{item.itemCount}}</span>
                          </template>
                          <template v-else>-</template>
                      </td>
                      <td>{{departmentTypes[item.dutyType] || '-'}}</td>
                      <td>
                          <template v-if="item.evaluatorUsers && item.evaluatorUsers.length > 0">
                              <div class="allow-click" @click="lookInspector(item.evaluatorUsers)">
                                  {{item.evaluatorUsers.map(staff => {
                                  return staff.name;
                              }).join('、')}}
                              </div>
                          </template>
                          <template v-else>-</template>
                      </td>
                      <td>
                          <div class="btn-group">
                              <button
                                  type="button"
                                  class="btn btn-primary dropdown-toggle"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                              >
                                  操作
                              </button>
                              <ul class="dropdown-menu">
                                  <li @click="openAddInspector(item)"><a style="width: 100%;">设置检查人</a></li>
                                  <!--                    <li @click="openUploadExcel(item.id)"><a>添加考核项</a></li>-->
                                  <li @click="changeAssessmentEnableStatus(item)"><a style="width: 100%;">{{item.isEnable ? '禁用' : '启用'}}</a></li>
                                  <!--                    <li @click="openEditAssessmentDialog(item)"><a>修改</a></li>-->
                                  <li @click="deleteAssessment(item.id)"><a style="width: 100%;">删除</a></li>
                              </ul>
                          </div>
                      </td>
                  </tr>
              </template>
          </template>
      </CSTable>
      <Pagination />

    </div>
    <!--  添加考核弹窗  -->
    <CSDialog :dialog-title="addAssessmentDialog.title" :dialog-visible="addAssessmentDialog.visible"
      @onClose="addAssessmentDialog.onClose" @onConfirm="addAssessmentDialog.onConfirm"
      :dialog-width="446">
      <template v-slot:dialog-content>
        <div class="dialog-form">
          <div class="dialog-form-item" v-if="TAB_BAR.SYSTEM !== checkedTabBar">
            <div class="dialog-form-item-label">考核名称</div>
            <div>
              <input type="text" placeholder="限20个字" maxlength="20" v-model="addAssessmentDialog.data.name" />
            </div>
          </div>
          <div class="dialog-form-item">
            <div class="dialog-form-item-label">考核部门</div>
            <div>
              <CSSelect height="40px" style="width: 250px;" i-width="36px">
                <select v-model="addAssessmentDialog.data.dutyType"
                      :disabled="addAssessmentDialog.isEdit"
                      class="select"
                        style="color: #999999"
                     >
                  <option value="">请选择</option>
                  <option v-for="(name, type) in departmentTypes" :key="type"
                          :value="type"  >

                    {{name}}
                  </option>
                </select>
              </CSSelect>
            </div>
          </div>
        </div>
      </template>
    </CSDialog>

    <!--  上传考核项弹窗  -->
    <CSDialog
        :dialogVisible="uploadExcelDialog.visible"
        :dialogWidth="550"
        dialog-title="添加考核项"
        @onClose="closeUploadExcel"
        @onConfirm="uploadExcel"
    >
      <template v-slot:dialog-content>
        <div class="upload-excel-dialog">
          <div class="form-item">
            <div class="form-item-label">考核项</div>
            <div>
              <input
                  type="file"
                  hidden
                  id="uploadExcel"
                  @change="chooseExcel($event)"
              />
              <input
                  type="text"
                  class="cs-input"
                  readonly
                  v-model="uploadExcelDialog.file.name"
                  placeholder="请选择"
              />
              <button
                  class="btn btn-primary"
                  style="margin-left: 12px;border-radius: 6px;"
                  @click="triggerChooseExcel"
              >
                浏览
              </button>
            </div>
          </div>
          <div class="form-item">
            <div class="form-item-label">模版</div>
            <div>
              <a
                  class="allow-click"
                  :href=" checkedTabBar === TAB_BAR.BUSINESS ? 'https://cosmos-img.oss-cn-beijing.aliyuncs.com/excel/%E6%B7%BB%E5%8A%A0%E4%B8%9A%E5%8A%A1%E8%80%83%E6%A0%B8%E9%A1%B9.xlsx' : 'https://cosmos-img.oss-cn-beijing.aliyuncs.com/excel/%E6%B7%BB%E5%8A%A0%E9%80%9A%E7%94%A8%E8%80%83%E6%A0%B8%E9%A1%B9.xlsx'"
                  target="_blank"
              >下载</a
              >
            </div>
          </div>
        </div>
      </template>
    </CSDialog>
    <!--  设置检查人  -->
    <CSDialog dialog-title="设置检查人" :dialog-visible="addInspectorDialog.visible"
      :dialog-width="460" @onClose="addInspectorDialog.onClose" @onConfirm="addInspectorDialog.onConfirm">
      <template v-slot:dialog-content>
        <div class="dialog-form">
          <div class="dialog-form-item">
            <div class="dialog-form-item-label" style="width: 72px;vertical-align: top;">检查人</div>
            <div style="max-width: 284px;">
              <p class="inspector" v-for="(evaluator, key) in addInspectorDialog.evaluatorIds"
                :key="key">
                <CSSelect style="width: 250px" height="40px" i-width="36px">
                  <select v-model="evaluator.id">
                    <option value="">请选择</option>
                    <option v-for="staff in staffList" :key="staff.id"
                            :value="staff.id">
                      {{staff.name}}
                    </option>
                  </select>
                </CSSelect>
                <svg class="icon" aria-hidden="true" v-if="key > 0" @click="addInspectorDialog.deleteInspector(key, evaluator)">
                  <use xlink:href="#icon-menushanchu"></use>
                </svg>
              </p>
            </div>
          </div>
          <div class="dialog-form-item">
            <div class="dialog-form-item-label" style="width: 72px;">
              &nbsp;
            </div>
            <div>
              <button class="btn btn-primary" style="font-size: 24px;width: 100px;padding: 0;height: 40px;" @click="addInspectorDialog.addInspector">
                <svg class="icon" aria-hidden="true" style="font-size: 18px;vertical-align: middle">
                  <use xlink:href="#icon-menujiahao"></use>
                </svg>
                <span style="display:inline-block;vertical-align: middle; margin-left: 5px;line-height: 36px">添加</span>
              </button>
            </div>
          </div>
        </div>
      </template>
    </CSDialog>
    <!--  查看巡检项  -->
    <CSDialog :dialog-visible="assessmentItemsDialog.visible" :dialog-width="870"
      @onClose="assessmentItemsDialog.onClose" :dialog-show-confirm-btn="false"
      dialog-cancel-btn-text="关闭"
    >
      <template v-slot:dialog-content>
        <div class="assessment-items-dialog">
          <div class="assessment-items-header" v-if="TAB_BAR.SYSTEM !== checkedTabBar">
            <input type="text" placeholder="搜索考核项" v-model="assessmentItemsDialog.keyword">
            <button class="btn btn-primary" @click="assessmentItemsDialog.searchItems">查询</button>
          </div>
          <div class="assessment-items-content">
            <div style="padding: 0 1px;">
              <table class="table modal-inner-table">
                <thead>
                <tr>
                  <th>考核项</th>
                  <th>{{
                      assessmentItemsDialog.assessmentType !== TAB_BAR.COMMON ? '扣分' : '分值'
                  }}</th>
                  <th v-if="assessmentItemsDialog.assessmentType !== TAB_BAR.SYSTEM" style="width: 140px;">操作</th>
                </tr>
                </thead>
                <tbody>
                <template v-if="assessmentItemsDialog.items.length > 0">
                  <tr v-for="item in assessmentItemsDialog.items"
                      :key="item.id">
                    <td>{{item.name || '-'}}</td>
                    <td>{{item.score || '-'}}</td>
                    <td v-if="assessmentItemsDialog.assessmentType !== TAB_BAR.SYSTEM">
                      <span class="allow-click" @click="openEditAssessmentItem(item)">修改</span>
                      <span class="allow-click"
                            @click="deleteAssessmentItem(item.id)">删除</span>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </template>
    </CSDialog>
    <!--  修改考核项  -->
    <CSDialog :dialog-width="916" :dialog-visible="editQualityAssessmentItemDialog.visible"
      @onClose="editQualityAssessmentItemDialog.onClose" @onConfirm="editQualityAssessmentItemDialog.onConfirm"
      dialog-title="修改考核项"
    >
      <template v-slot:dialog-content>
        <div class="dialog-form edit-assessment-dialog">
          <div class="dialog-form-item">
            <div class="dialog-form-item-label top">考核项</div>
            <div>
              <textarea placeholder="请输入" v-model="editQualityAssessmentItemDialog.data.name"></textarea>
            </div>
          </div>
          <div class="dialog-form-item">
            <div class="dialog-form-item-label">扣分分数</div>
            <div>
              <input type="number" placeholder="请输入" v-model="editQualityAssessmentItemDialog.data.score">
            </div>
          </div>
        </div>
      </template>
    </CSDialog>
  </div>
</template>

<script>
import CSTabBar from "@/components/common/CSTabBar";
import CSSelect from "@/components/common/CSSelect";
import CSDialog from "@/components/common/CSDialog";
import {DEPARTMENT_TYPES, ORG_LEVEL} from "@/constant";
import {
  addQualityAssessmentItemUrl,
  queryDepartmentStaffUrl,
  queryDepartmentUrl,
  queryQualityAssessmentUrl,
  editQualityAssessmentUrl,
  deleteQualityUrl,
  changeQualityAssessmentEnableUrl,
  queryQualityAssessmentItemsUrl,
  uploadQualityAssessmentItemUrl,
  addEvaluatorUsersUrl,
  deleteEvaluatorUsersUrl,
  deleteQualityAssessmentItemUrl,
  updateQualityAssessmentItemUrl,
} from "@/requestUrl";
import Pagination from "@/components/Pagination";
import CSTable from "@/components/common/CSTable";

const TAB_BAR = {
  BUSINESS: 1,
  COMMON: 2,
  SYSTEM: 3,
};
export default {
  name: "QualityAssessment",
  components: {
      CSTable,
    CSTabBar,
    CSSelect,
    CSDialog,
    Pagination,
  },
  data() {
    return {
      TAB_BAR,
        filterHeight: 0,
      tabBar: {
        [TAB_BAR.BUSINESS]: '业务考核',
        [TAB_BAR.COMMON]: '通用考核',
        [TAB_BAR.SYSTEM]: '系统自动考核',
      },
      checkedTabBar: 1,
      editQualityAssessmentItemDialog: {
        visible: false,
        data: {
          name: "",
          score: '',
        },
        onConfirm() {},
        onClose: () => {
          this.editQualityAssessmentItemDialog.visible = false;
        }
      },
      departmentTypes: {
        [DEPARTMENT_TYPES.DIRECTOR]: "总监办公室",
        [DEPARTMENT_TYPES.MANAGER]: "经理办公室",
        [DEPARTMENT_TYPES.ENGINEERING]: "工程部",
        [DEPARTMENT_TYPES.CUSTOMER_SERVICE]: "客服部",
        [DEPARTMENT_TYPES.PROCTER_GAMBLE]: "保洁部",
        [DEPARTMENT_TYPES.SECURITY]: "保安部",
        [DEPARTMENT_TYPES.PERSONNEL]: "人事部",
        [DEPARTMENT_TYPES.FINANCIAL]: "财务部",
        [DEPARTMENT_TYPES.OPERATIONS]: "运营部",
        [DEPARTMENT_TYPES.INVESTMENT_PROMOTION]: "招商部",
        [DEPARTMENT_TYPES.QUALITY]: "品质管理部",
      },
      /*departmentTypes: [
        { name: "总监办公室", id: DEPARTMENT_TYPES.DIRECTOR },
        { name: "经理办公室", id: DEPARTMENT_TYPES.MANAGER },
        { name: "工程部", id: DEPARTMENT_TYPES.ENGINEERING },
        { name: "客服部", id: DEPARTMENT_TYPES.CUSTOMER_SERVICE },
        { name: "保洁部", id: DEPARTMENT_TYPES.PROCTER_GAMBLE },
        { name: "保安部", id: DEPARTMENT_TYPES.SECURITY },
        { name: "人事部", id: DEPARTMENT_TYPES.PERSONNEL },
        { name: "财务部", id: DEPARTMENT_TYPES.FINANCIAL },
        { name: "运营部", id: DEPARTMENT_TYPES.OPERATIONS },
        { name: "招商部", id: DEPARTMENT_TYPES.INVESTMENT_PROMOTION },
        { name: "品质管理部", id: DEPARTMENT_TYPES.QUALITY },
      ],*/
      addInspectorDialog: {
        visible: false,
        evaluatorIds: [
            {
              id: '',
              isNewAdd: true, // 是否是新添加，  为true时要用于删除巡检人传参
            }
        ],
        deleteInspectorIds: [],
        onClose: () => {
          this.addInspectorDialog.visible = false;
        },
        addInspector: () => {
          this.addInspectorDialog.evaluatorIds.push({id: '', isNewAdd: true});
        },
        /**
         * 删除巡检人
         * @param {Number} index 选中的巡检人数组中的下标
         * @param {Object} inspector 当前要删除的巡检人信息
         * */
        deleteInspector: (index, inspector) => {
          this.addInspectorDialog.evaluatorIds.splice(index, 1);
          if (!inspector.isNewAdd) {
              this.addInspectorDialog.deleteInspectorIds.push(inspector.id);
          }
        },
        onConfirm() {}
      },
      addAssessmentDialog: {
        visible: false,
        title: '',
        isEdit: false,
        data: {
          dutyType: '',
          name: '',
          type: '',
          regionCode: this.$vc.getCurrentRegion().code,
        },
        onClose: () => {
          this.addAssessmentDialog.visible = false;
        },
        onConfirm() {},
      },
      queryParams: {
        dutyType: '',
        evaluatorId: '',
      },
      staffList: [],
      assessmentList: [],
      uploadExcelDialog: {
        visible: false,
        file: { name: "" },
        id: "",
        regionCode: this.$vc.getCurrentRegion().code,
      },
      assessmentItemsDialog: {
        visible: false,
        items: [],
        assessmentType: TAB_BAR.BUSINESS,
        keyword: '',
        onClose: () => {
          this.assessmentItemsDialog.visible = false;
        },
        searchItems: () => {}
      }
    }
  },
  async mounted() {
    window.addEventListener("keydown",this.assessListDown);
    const staffInfo = this.$vc.getCurrentStaffInfo();
    const departmentList = await this.queryDepartmentList(ORG_LEVEL.DEPARTMENT, 1, 100, staffInfo.companyCode, DEPARTMENT_TYPES.QUALITY)
    this.staffList = await this.getStaffList(departmentList[0].code);
    this.getAssessmentList();
    this.$vc.on(this.$route.path, "pagination_page", "event", page => {
      this.getAssessmentList(page);
    })
      this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
      window.addEventListener('resize', () => {
          this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
      })
  },
  beforeDestroy(){
    window.removeEventListener("keydown",this.assessListDown);
  },
  deactivated(){
    window.removeEventListener("keydown",this.assessListDown);
  },
  methods: {
    assessListDown(e){
      if(e.keyCode !== 13){
        return;
      }
      if(this.checkedTabBar !== TAB_BAR.SYSTEM){
        this.getAssessmentList();
      }else if(this.TAB_BAR.SYSTEM !== checkedTabBar){
        this.assessmentItemsDialog.searchItems();
      }
    },
    /**
     * 打开修改检查项
     * @param {Object} item 检查项
     * */
    openEditAssessmentItem(item) {
      this.editQualityAssessmentItemDialog.data = {
        name: item.name,
        score: item.score,
      };
      this.editQualityAssessmentItemDialog.visible = true;
      this.editQualityAssessmentItemDialog.onConfirm = () => {
        this.$fly.post(updateQualityAssessmentItemUrl, {
          regionCode: this.$vc.getCurrentRegion().code,
          id: item.id,
          ...this.editQualityAssessmentItemDialog.data
        }, {
          headers: {
            isForm: true,
          }
        })
        .then(res => {
          if (res.code !== 0) {
            return ;
          }
          this.$vc.toast('修改成功');
          this.editQualityAssessmentItemDialog.onClose();
          this.assessmentItemsDialog.searchItems();
        })
      }
    },
    deleteAssessmentItem(id) {
      this.$CSDialog.confirm({
        title: '提示',
        message: '确定删除吗?',
        onConfirm: () => {
          this.$fly.get(deleteQualityAssessmentItemUrl, {
            id
          })
          .then(res => {
            if (res.code !== 0) {
              return ;
            }
            this.$vc.toast('删除成功');
            this.$CSDialog.instance.closeDialog();
            this.assessmentItemsDialog.searchItems();
          })
        }
      })

    },
    /**
     * 打开考核项弹窗
     * @param {Number} detailId 考核id
     * @param {Number} itemCount 考核项数量
     * */
    async openAssessmentItemsDialog(detailId, itemCount, state) {
        if (itemCount === 0) {
            this.$vc.message('该考核没有考核项');
            return ;
        }
      this.$router.push({name: "AssessmentPointsDeduction", params:{detailId, state}});

      /*this.assessmentItemsDialog.keyword = '';
      this.assessmentItemsDialog.assessmentType = this.checkedTabBar;
      this.assessmentItemsDialog.items = await this.getQualityAssessmentItems(detailId, this.assessmentItemsDialog.keyword);
      this.assessmentItemsDialog.visible = true;
      this.assessmentItemsDialog.searchItems = async () => {
        this.assessmentItemsDialog.items = await this.getQualityAssessmentItems(detailId, this.assessmentItemsDialog.keyword);
      }*/
    },
    /**
     * 获取品质管理巡检项
     * @param {Number} detailId 考核id
     * @param {String} search 搜索的关键字
     * */
    getQualityAssessmentItems(detailId, search) {
      return this.$fly.post(queryQualityAssessmentItemsUrl, {
        regionCode: this.$vc.getCurrentRegion().code,
        detailId, search
      })
      .then(res => {
        if (res.code !== 0) {
          return ;
        }
        return res.data;
      })
    },
    /**
     * 查看检查人
     * @param {Array} inspectors 检查人
     * */
    lookInspector(inspectors) {
      this.$CSDialog.alert({
        title: '检查人',
        messageHtml: `
        <div class="comment-panel text-center">
            ${inspectors.map((inspector, index) => {
              return `<div class="${`item ${index === inspectors.length - 1 ? '' : 'mb-20'} text-center`}">
                      <div>
                         ${inspector.name}
                      </div>
                  </div>`;
            }).join('')}
        </div>`,
      })
    },
    /*
    * 打开添加设置检查人弹窗
    * @param {Object} assessment 巡检信息
    * */
    openAddInspector(assessment) {
      this.addInspectorDialog.deleteInspectorIds = [];
      if (assessment.evaluatorUsers && assessment.evaluatorUsers.length > 0) {
        this.addInspectorDialog.evaluatorIds = assessment.evaluatorUsers.map(evaluatorUser => {
          return {id: evaluatorUser.id, isNewAdd: false};
        });
      } else {
        this.addInspectorDialog.evaluatorIds = [{id: '', isNewAdd: true}];
      }
      this.addInspectorDialog.onConfirm = async () => {
        await this.deleteInspectorRequest(assessment.id);
        this.addInspectorRequest(assessment.id)
      }
      this.addInspectorDialog.visible = true;
    },
    /**
     * 删除巡检人请求
     * @param {Number} detailId 检查id
     * */
    deleteInspectorRequest(detailId) {
      let {deleteInspectorIds, evaluatorIds} = this.addInspectorDialog;
      evaluatorIds.forEach(evaluator => {
        console.log(evaluator, '巡检人的信息');
        if (!evaluator.isNewAdd) {
          deleteInspectorIds.push(evaluator.id);
        }
      })
      if (deleteInspectorIds.length === 0) {
        return ;
      }
     return this.$fly.post(deleteEvaluatorUsersUrl, {
        detailId,
        regionCode: this.$vc.getCurrentRegion().code,
        evaluatorIds: JSON.stringify(deleteInspectorIds),
      })
      .then(res => {
        if (res.code !== 0) {
          return false;
        }
        return res.data;
      })
    },
    /**
     * 添加巡检人请求
     * @param {Number} detailId 检查id
     * */
    addInspectorRequest(detailId) {
      const evaluatorIds = JSON.stringify(this.addInspectorDialog.evaluatorIds.map(evaluator => {
        return evaluator.id;
      }));
      this.$fly.post(addEvaluatorUsersUrl, {
        detailId,
        regionCode: this.$vc.getCurrentRegion().code,
        evaluatorIds,
      }, {
        headers: {
          isForm: true,
        }
      })
      .then(res => {
        if (res.code !== 0) {
          return ;
        }
        this.$vc.toast('添加成功');
        this.addInspectorDialog.onClose();
        this.getAssessmentList();
      })
    },
    changeTabBar(key) {
      this.checkedTabBar = +key;
      this.queryParams =  {
        dutyType: '',
        evaluatorId: '',
      };
      this.getAssessmentList();
    },
    triggerChooseExcel() {
      document.querySelector("#uploadExcel").click();
    },
    chooseExcel(event) {
      return this.$vc.chooseFile(event, (file) => {
        const excelTypes = ['xlsx', 'xls', 'xlsm', 'xlsb', 'xltx', 'xlt'],
            fileSplitArr = file.name.split('.');
        if (!excelTypes.includes(fileSplitArr[fileSplitArr.length - 1])) {
          this.$vc.message(`仅能上传${excelTypes.join('、')}格式的文件`);
          return ;
        }
        this.uploadExcelDialog.file = file;
      });
    },
    // 打开上传excel
    openUploadExcel(id) {
      this.uploadExcelDialog.visible = true;
      this.uploadExcelDialog.file = {
        name: "",
      };
      this.uploadExcelDialog.id = id;
    },
    closeUploadExcel() {
      this.uploadExcelDialog.visible = false;
    },
    /**
     * 上传excel
     * @param {Object} file 文件信息
     *  */
    uploadExcel() {
      const { file, id, regionCode } = this.uploadExcelDialog;
      let formData = new FormData();
      if (file === null || file.name === '') {
        this.$vc.toast('请选择Excel');
        return;
      }
      formData.append("file", file);
      formData.append("detaiId", id);
      formData.append("regionCode", regionCode);
      this.$fly
          .post(uploadQualityAssessmentItemUrl, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              isForm: true,
            },
          })
          .then((res) => {
            if (res.code !== 0) {
              return;
            }
            if (res.data && res.data.length > 0) {
              this.$vc.message(res.data[0]);
              return;
            }
            this.$vc.toast("上传成功");
            this.closeUploadExcel();
            this.getAssessmentList();
          });
    },
    /**
     * 删除考核
     * @param {Number} id 考核id
     * */
    deleteAssessment(id) {
      this.$CSDialog.confirm({
        title: '提示',
        message: '确定删除吗?',
        onConfirm: () => {
          this.$fly.get(deleteQualityUrl, {
            id
          })
          .then(res => {
            if (res.code !== 0) {
              return ;
            }
            this.$vc.toast('删除成功');
            this.$CSDialog.instance.closeDialog();
            this.getAssessmentList();
          })
        }
      })
    },
    /**
     * 更改启用，禁用状态
     * @param {Object} assessment 考核信息
     * */
    changeAssessmentEnableStatus(assessment) {
      const {isEnable, id} = assessment;
      this.$CSDialog.confirm({
        title: '提示',
        message: `确定${isEnable ? '禁用' : '启用'}吗?`,
        onConfirm: () => {
          this.$fly.post(changeQualityAssessmentEnableUrl, {
            id,
            isEnable: Number(!isEnable),
            regionCode: this.$vc.getCurrentRegion().code
          })
              .then(res => {
                if (res.code !== 0) {
                  return ;
                }
                this.$vc.toast(`${isEnable ? '禁用' : '启用'}成功`);
                this.$CSDialog.instance.closeDialog();
                this.getAssessmentList();
              })
        }
      })
    },
    /**
     * 打开编辑考核项
     * @param {Object} assessmentInfo 考核信息
     * */
    openEditAssessmentDialog(assessmentInfo) {
      const title = {
        1: '修改业务考核',
        2: '修改通用考核',
        3: '修改系统考核',
      };
      this.addAssessmentDialog.data.name = assessmentInfo.name;
      this.addAssessmentDialog.isEdit = true;
      this.addAssessmentDialog.title = title[this.checkedTabBar];
      this.addAssessmentDialog.data.dutyType = assessmentInfo.dutyType;
      this.addAssessmentDialog.visible = true;
      this.addAssessmentDialog.onConfirm = () => {
        this.$fly.post(editQualityAssessmentUrl, {
          regionCode: this.$vc.getCurrentRegion().code,
          id: assessmentInfo.id,
          name: this.addAssessmentDialog.data.name,
        }, {
          headers: {
            isForm: true,
          }
        })
        .then(res => {
          if (res.code !== 0) {
            return ;
          }
          this.$vc.toast('修改成功');
          this.addAssessmentDialog.onClose();
          this.getAssessmentList();
        })
      }
    },
    // 打开添加考核项弹窗
    openAddAssessmentDialog() {
      const title = {
        1: '添加业务考核',
        2: '添加通用考核',
        3: '添加系统考核',
      };
      this.addAssessmentDialog.isEdit = false;
      this.addAssessmentDialog.title = title[this.checkedTabBar];
      this.addAssessmentDialog.data = {
        dutyType: '',
        name: '',
        regionCode: this.$vc.getCurrentRegion().code,
        type: this.checkedTabBar,
      };
      this.addAssessmentDialog.onConfirm = () => {
        this.$fly.post(addQualityAssessmentItemUrl, this.addAssessmentDialog.data, {
          headers: {
            isForm: true,
          }
        })
          .then(res => {
            if (res.code !== 0) {
              return ;
            }
            this.$vc.toast('添加成功');
            this.addAssessmentDialog.onClose();
            this.getAssessmentList();
          })
      }
      this.addAssessmentDialog.visible = true;
    },
    // 查询考核
    getAssessmentList(pageNo = 1, pageSize = 10) {
      this.$fly.post(queryQualityAssessmentUrl, {
        type: this.checkedTabBar,
        regionCode: this.$vc.getCurrentRegion().code,
        pageNo,
        pageSize,
        ...this.queryParams,
      })
      .then(res => {
        if (res.code !== 0) {
          return ;
        }
        if(pageNo === 1){
          this.$vc.emit(this.$route.path, 'pagination', 'init', {
            pageSize,
            total:res.data.total,
            currentPage: pageNo,
          })
        }
        this.assessmentList = res.data.datas;
      })
    },
    /**
     * 查询公司/部门接口
     * @param {Number} level 0:代表公司
     * @param {NUmber} pageNo 分页页数
     * @param {Number} pageSize 每次查看的数量
     * @param {Number | String} parentCode 公司code
     *  */
    queryDepartmentList(
        level = ORG_LEVEL.COMPANY,
        pageNo = 1,
        pageSize = 100,
        parentCode,
        dutyType  = ''
    ) {
      return this.$fly
          .post(queryDepartmentUrl, {
            regionCode: this.$vc.getCurrentRegion().code,
            code: "",
            name: "",
            dutyType,
            parentCode,
            level,
            pageNo,
            pageSize: level == ORG_LEVEL.COMPANY ? 100 : pageSize,
          })
          .then((res) => {
            if (res.code !== 0) {
              return [];
            }

            return res.data;
          });
    },
    // 获取员工列表
    getStaffList(departmentCode) {
      return this.$fly
          .post(queryDepartmentStaffUrl, {
            regionCode: this.$vc.getCurrentRegion().code || "",
            departmentCode,
            jobLevel: "",
            isOnTheJob: true,
          })
          .then((res) => {
            if (res.code !== 0) {
              return;
            }
            return res.data;
          });
    },
  },
}
</script>

<style lang="stylus" scoped>
.filter-panel
  .cs-select
    &:not(:last-child)
      margin-right 20px
.dialog-form
  padding 27px 30px
  font-size 24px
  color #000
  &-item
    &:not(:last-of-type)
      margin-bottom 23px
    &-label
      width 96px
      text-align right
      margin-right 40px
      display inline-block
      vertical-align middle
    & > div
      display inline-block
      vertical-align middle
      .inspector
        margin-bottom 0
        display inline-block
        vertical-align middle
        &:not(:last-of-type)
          margin-bottom 17px
        .icon
          margin-left 10px
      .cs-select
        width 250px
      input
        &::placeholder
          color #999
        width 250px
        height 40px
        border-radius 4px
        padding 0 5px
        border 1px solid #979797
.upload-excel-dialog
  padding 27px 30px 30px 30px
  .form-item
    font-size 24px
    &-label
      width 108px
      margin-right 40px
      text-align right
      color #000
    &:not(:last-of-type)
      margin-bottom 23px
    div
      display inline-block
      vertical-align middle
      .btn-primary
        width 80px
        height 40px
        padding 0
        line-height 40px
        font-size 24px
        vertical-align middle
    .cs-input
      width 250px
      border 1px solid #979797
      border-radius 4px
      vertical-align middle
    .cs-select
      display flex
      --icon-line-height 40px
      select
        width 250px
        height 100%
.select[disabled]
  color:#000;opacity:1
.assessment-items-dialog
  padding 30px
  font-size 24px
  .assessment-items
    &-header
      margin-bottom 30px
      input
        width 300px
        padding 0 10px
        box-sizing border-box
        margin-right 30px
        border-radius 4px
        border 1px solid #979797
        &::placeholder
          color #999
      .btn
        height 40px
        padding 0
        width 70px
        font-size 24px

    &-content
      max-height 400px
      overflow-y auto
      .allow-click
        &:not(:last-child)
          margin-right 10px
.edit-assessment-dialog
  .dialog-form
    &-item
      &-label
        &.top
          vertical-align top
      & > div
        textarea
          width 720px
          height 200px
          background #F0F0F0
          border-radius 10px
          padding 10px
          resize none
          border none
          outline none
        input
          width 250px
          height 40px
          border-radius 4px
          padding 10px
          box-sizing border-box
          border 1px solid #979797
</style>
